import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content from '../components/Content'
import HeaderMeta from '../components/HeaderMeta';

import {
  ResponsiveMaxWidthFrame,
  SectionGroup,
  PageNameBox,
  HtmlBody,
} from "@minware/ui-minware";

export const SubprocessorsPageTemplate = ({
  pretitle,
  title,
  content,
  contentComponent
}) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h3 className="pretitle">
              {pretitle}
            </h3>
            <h1 className="title">
              {title}
            </h1>
            <PageContent className="content" content={content} />
          </div>
        </div>
      </div>
    </section>
  )
}

SubprocessorsPageTemplate.propTypes = {
  pretitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const SubprocessorsPage = ({ data }) => {
  const { main } = data;


  const headerWithCanon = {
    ...main?.frontmatter?.header,
    canonicalUrl: '/legal/sub-processors',
  };

  return (
    <Layout>
      <HeaderMeta header={headerWithCanon}/>

      <ResponsiveMaxWidthFrame maxWidth="600px">
        <SectionGroup>
          <PageNameBox>{main.frontmatter.pageName}</PageNameBox>
        </SectionGroup>
        <HtmlBody noListIndent={true} children={main.html}/>
      </ResponsiveMaxWidthFrame>
    </Layout>
  )
}

SubprocessorsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SubprocessorsPage

export const SubprocessorsPageQuery = graphql`
  query SubprocessorsPage($id: String!) {
    main: markdownRemark(id: { eq: $id }) {
      ...HeaderMeta
      html
      frontmatter {
        pageName,
      }
    }
  }
`
